<template>
	<div class="stamp">
		<v-Header></v-Header>
		<div class="stampBox">
			<div class="stampBanner bannerBox">
				<div class="centerBox">
					<div class="bannerBox">
						<div class="bannerInfo">
							<h1>Stamp</h1>
							<p>Stamp is the labels builded by creators to mark specific addresses collections. Creators can collect on-chain wallet behavioral data and consolidate into easily understandable Event and Property. For example, NFT whales in Evmos.</p>
						</div>
						<div class="bannerImg">
							<img src="../images/stamp/bannerright.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="stampList">
				<div class="centerBox">
					<div class="listBox">
						<ul>
							<li v-for="(item,index) in list" :key="index">
								<div class="listItem" @click="goStampDetail(item.id)">
									<div class="itemIntroduce">
										<h2>{{item.name}}</h2>
										<p>{{item.describe}}</p>
									</div>
									<div class="ItemInfo">
										<p>
											<span>Type</span>
											<span>{{item.type}}</span>
										</p>
										<p>
											<span>Created By</span>
											<span>{{changeAddress(item.created_by)}}</span>
										</p>
									</div>
								</div>
							</li>
							
						</ul>
					</div>
				</div>
			</div>
		</div>
		<v-Footer></v-Footer>
	</div>
</template>

<script>

export default {
	name: 'Stamp',
	computed:{
		changeAddress() {
			return (val) => {
				var str = val
				return str.substring(0,6)+'…'+str.substring(38)
			}
		}
	},
	data () {
		return {
			list:[]
		}
	},
	mounted () {
		var appData = window.appData
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getstamplist',
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.list = data.data
			} 
		});
	},
	methods: {
		goStampDetail(index){
			this.$router.push({path:`/stampdetail?id=${index}`})
		}
	}
}
</script>

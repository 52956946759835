<template>
	<div class="soulIDEdit">
		<v-Header></v-Header>
		<div class="soulIDEditBox centerBox">
			<div></div>
			<div class="goBack" @click="goBack">
				<i class="el-icon-arrow-left"></i>
				Edit Profile
			</div>
			<div class="infoBox">
				<ul>
					<li><p>Account Setting</p></li>
					<li><p>Socicl Link</p></li>
				</ul>
				<div class="info">
					<div>
						<div class="head">
							<div>
								<img src="../images/soulID/headImg.png" alt="">
								<img src="../images/soulID/edit.png" alt="">
							</div>
							<div>
								<p>Profile Image</p>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet.</p>
							</div>
						</div>
						<div class="name">
							<label for="username">Username</label>
							<input id="name" type="text" autocomplete="off">
							<div>Save</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-Footer></v-Footer>
	</div>
</template>

<script>

export default {
	name: 'SoulID',
	components: {
		
	},
	data () {
		return {

		}
	},
	mounted () {

	},
	methods: {
		goBack(){
			this.$router.push({path:`/soulid`})
		}
	}
}
</script>

<template>
  <div class="stampDetail">
    <v-Header></v-Header>
    <div class="stampDetailBox centerBox">
      <div class="ditialBox">
        <h1>{{itemData.name}}</h1>
        <h3>Created By <span>{{changeAddress(itemData.created_by)}}</span></h3>
        <div v-html="itemData.describe"></div>
        <ul>
          <li>
            <p>Type</p>
            <p>{{itemData.type}}</p>
          </li>
          <li>
            <p>Source</p>
            <p>{{itemData.source}}</p>
          </li>
          <li>
            <p># of Address</p>
            <p>{{itemData.of_address}}</p>
          </li>
          <li>
            <p>Last Update Time</p>
            <p>{{itemData.last_update_time}}</p>
          </li>
        </ul>
      </div>
    </div>
    <v-Footer></v-Footer>
  </div>
</template>

<script>

export default {
  name: 'StampDetail',
  computed:{
		changeAddress() {
			return (val) => {
				var str = val
				return str.substring(0,6)+'…'+str.substring(38)
			}
		}
	},
	data () {
		return {
      itemData:{
        name:'',
        minInfo:'',
        type:'',
        created:'0x0000000000000000000000000000000000000000',
        info:'',
        source:'',
        ofAddress:'',
        lastUpdate:'',
      }
		}
	},
	mounted () {
    var id = this.$route.query.id
		this.itemData = window.appData.station.stampList[id]
    this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getstampdetail',
        id:id
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.itemData = data.data
			} 
		});
	},
	methods: {
		
	}
}
</script>

<template>
	<div class="badgeDetal">
		<v-Header ref="vHeader"></v-Header>
		<div v-if="Loading" style="text-align: center;margin: 300px 100px 300px;">Loading <i class="el-icon-loading" ></i></div>
		<div v-else-if="!Loading" class="badgeDetalBox ">
			<div class="badgeDetalTop">
				<div class="mintBox">
					<div>
						<div class="mintImg" :style="{ 'width' : detailId == 3 ? '100%' : '70%' }">
							<img v-if="nftInfo.image != ''" :src="nftInfo.image" alt="">
						</div>
						<div v-if="userAddress == ''" class="btn" @click="connect"><h1>Connect Wallet</h1></div>
						<div v-else-if="nftBalance != 0 && minted" class="btn btnDisable" ><h1>Mint Success</h1></div>
						<div v-else-if="!minted" :class="!winners ? 'btn btnDisable' : !ifStar ? 'btn btnDisable' : 'btn'" @click="mint"><h1>Mint <i v-if="mintting" class="el-icon-loading" ></i></h1></div>
						<div v-else class="btn" @click="Receive"><h1>Receive now</h1></div>
						<h2  v-if="!winners || userAddress == ''" class="tip" >You are NOT eligible</h2>
						<h2  v-else class="tip" ></h2>
					</div>
				</div>
				<div class="introduceBox">
					<div>
						<h1>{{nftInfo.name}}</h1>
						<div v-html="nftInfo.describe"></div>
						<!-- <p class="text">Campaign period: {{nftInfo.ClaimablePeriodStart}} - {{nftInfo.ClaimablePeriodEnd}} (SGT)</p>
						<p class="title">Task:</p>
						<p class="text">1 Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
						<p class="text">2 Sociis natoque penatibus et magnis dis parturient montes</p>
						<p class="title">Campaign link:</p>
						<p class="text">Token rewards will be issued within 7 business days, Please join the imToken Discord and contact us if you have any questions.</p> -->
						<h2 class="mintNum">NFT Minted {{numMinted}}</h2>
					</div>
				</div>
			</div>
			<div class="badgeDetalBottom">
				<div class="latestBox">
					<div>
						<h3>Latest Minted</h3>
						<ul v-if="mintedList.length != 0 && mintedList[0].time">
							<li v-for="(item,index) in mintedList" :key="index">
								<p><span>{{nftInfo.name}} #{{item.tokenId}}</span><span>{{changeTime(item.time)}}</span></p>
								<p><span>{{ changeAddress(item.addr) }}</span><span>https://escan.live/</span></p>
							</li>
						</ul>
						<div class="more" @click="goMore">
							<img src="../images/badgeDetail/ditleft.png" alt="">
							<h3>Transactions</h3>
							<img src="../images/badgeDetail/ditright.png" alt="">
						</div>
					</div>
				</div>
				<div class="infoBox">
					<div>
						<div class="claim">
							<div class="img">
								<img v-if="nftInfo.author_image != ''" :src="nftInfo.author_image" alt="">
							</div>
							<div class="name">
								<p>Space</p>
								<h2>{{ nftInfo.author_name }}</h2>
							</div>
							<div class="time">
								<p>Start Time</p>
								<p>{{nftInfo.start_time}}</p>
							</div>
						</div>
						<div class="contractAdd">
							<p>Contract Address</p>
							<p>{{ changeAddress(nftInfo.contract_address) }} <img @click="copy" src="../images/badgeDetail/copy.png" alt=""></p>
						</div>
						<div class="eligible">
							<h2>Who is Eligible</h2>
							<ul>
								<li v-for="(item,index) in nftInfo.stamp" :key="index"><img src="../images/badgeDetail/icon1.png" alt=""><span>{{item.name}}</span></li>
							</ul>
						</div>
						<p class="tip">Meet any of the above conditions: get 1 entry</p>
					</div>
				</div>
			</div>
		</div>
		<v-Footer></v-Footer>
	</div>
</template>

<script>
import { Winners, allNFTIndex, nftInfo, numMinted, claim, nftBalanceOf, tokenOfOwnerByIndex, tokenURI, getNumMinted, ownerOf, mintTime, judgeNetwork } from '../request.js'
import { formatDate } from '../utils/getTime.js'
export default {
	name: 'BadgeDetal',
	computed:{
		changeAddress() {
			return (val) => {
				var str = val
				return str.substring(0,6)+'…'+str.substring(38)
			}
		},
		changeTime(){
			return (val) => {
				var time = Math.ceil(new Date().getTime()/1000)
				var differ = Math.ceil(time - val)
				var text = ''
				if (differ <= 59) {
					text = differ
					return `${text} seconds ago`
				} else if (differ >= 60 && differ <= 119) {
					return `a minute ago`
				} else if (differ >= 119 && differ <= 3599) {
					text = Math.ceil(differ/60)
					return `${text} minutes ago`
				} else if (differ >= 3600 && differ <= 7199) {
					return `a hour ago`
				} else if (differ >= 7200 && differ <= 86399) {
					text = Math.ceil(differ/60/60)
					return `${text} hours ago`
				} else if (differ >= 86400 && differ <= 172799) {
					return `a day ago`
				} else if (differ >= 172800) {
					// console.log(differ)
					text = Math.ceil(differ/60/60/24)
					return `${text} days ago`
				}
			}
		}
	},
	data () {
		return {
			isCosmos:false,
			nftType:0,
			time:0,
			userAddress:'',userAddressEvmos:'',walletType:'',addressValue:'',
			contractAddr:'',
			cid:0,
			nftInfo:{
				image:'',
                name:'',
                author_image:'',
                author_name:'',
                describe:'',
				start_time:'',
				contract_address:'',
				stamp:[],
			},
			mintedList:[],
			appData:{},
			winners:false,
			mintting:false,
			numMinted:0,
			// ClaimablePeriodEnd = formatDate(res.ClaimablePeriodEnd*1000)
			// ClaimablePeriodStart = formatDate(res.ClaimablePeriodStart*1000)

			nftBalance:0,
			nftBalance1:0,
			minted:false,
			ifStar:false,
			Loading:true,
		}
	},
	mounted () {
		this.time = Math.ceil(new Date().getTime()/1000)
		this.userAddress = localStorage.getItem('userAddress') ? localStorage.getItem('userAddress').toLowerCase() : ''
		this.userAddressEvmos = localStorage.getItem('userAddressEvmos') ? localStorage.getItem('userAddressEvmos').toLowerCase() : ''
		this.walletType = localStorage.getItem('walletType') ? localStorage.getItem('walletType') : ''
		this.detailId = this.$route.query.id
		this.appData = window.appData
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getbadgedetail',
				id:this.detailId,
				address:this.userAddress
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.contractAddr = data.data.contract_address
				this.nftInfo = data.data
				if(this.nftInfo.chain_name == '' || this.nftInfo.chain_name == null){
					localStorage.setItem('isMetamask',true)
				} else {
					localStorage.setItem('isMetamask',false)
				}
				if(this.userAddress != ''){
					nftBalanceOf(this.contractAddr,this.userAddress).then(balance => {
						if(balance >0){
							this.nftBalance = balance
							this.minted = true
							tokenOfOwnerByIndex(this.contractAddr,balance-1,this.userAddress).then(tokenId => {
								//console.log(tokenId)
								tokenURI(this.contractAddr,tokenId).then(url => {
									//console.log(url)
									this.$.getJSON(url,(res) => {
										//console.log(res)
										this.nftInfo.image = res.image
										this.Loading = false
									})
								})
							})
						} else {
							this.Loading = false
						}
					})
				} else {
					this.Loading = false
				}

				this.getMintedList()
				// ownerOf, mintTime
				allNFTIndex(this.appData.nftFactory.contract,this.contractAddr).then(cid => {
					if(cid > 2){
						this.stationAddress = this.appData.stationCosmos.contract
						this.isCosmos = true
					} else {
						this.stationAddress = this.appData.station.contract
						this.isCosmos = false
					}
					if (this.walletType == 'cosmostation' && localStorage.getItem('cosmosType') != this.nftInfo.cosmosType && cid > 2) {
						localStorage.setItem('cosmosType',this.nftInfo.cosmosType)
						this.$refs.vHeader.connect('cosmostation')
					} else {
						localStorage.setItem('cosmosType','Evmos')
					}
					if ((this.nftInfo.chain_name != '' || this.nftInfo.chain_name != null) && this.walletType == 'cosmostation' && cid > 2) {
						this.addressValue = this.userAddressEvmos
					} else {
						this.addressValue = this.userAddress
					}
					if ((this.nftInfo.chain_name != '' || this.nftInfo.chain_name != null) && this.walletType == 'metamask' && cid > 2) {
						this.$refs.vHeader.switchShow = true
					}
					this.cid = cid
					nftInfo(this.stationAddress,this.cid,this.isCosmos).then(res => {
						this.ClaimablePeriodEnd = res.ClaimablePeriodEnd*1000
						this.ClaimablePeriodStart = res.ClaimablePeriodStart*1000
						var t = new Date().getTime()
						if ( t > this.ClaimablePeriodStart && t < this.ClaimablePeriodEnd) {
							this.ifStar = true
						} else {
							this.ifStar = false
						}

					})
					numMinted(this.stationAddress,this.cid,this.isCosmos).then(res => {
						this.numMinted = res
					})
					if(this.userAddress != ''){
						Winners(this.stationAddress,this.cid,this.userAddress,this.isCosmos).then(res => {
							this.winners = res
						})
					}
				})

			} 
		});
		
		
	},
	methods: {
		getMintedList(){
			getNumMinted(this.contractAddr).then(mintNum => {
				if (mintNum == 0) {
					return false
				}
				for (let i = mintNum; i > mintNum-0-5; i--) {
					// this.mintedList[5-i].tokenId = i
					//console.log(this.mintedList)
					ownerOf(this.contractAddr,i).then(addr => {
						// this.mintedList[5-i].addr = addr
						//console.log(addr)
						mintTime(this.contractAddr,i).then(time => {
							var obj = {
								tokenId:i,
								addr:addr,
								time:time
							}
							this.mintedList.push( obj)
							this.dataSort(this.mintedList)
							this.$forceUpdate()
							//console.log(time)
						})
					})
					
				}
			})
		},
		dataSort(arr){
			arr.sort(function(a,b){
				if (a.tokenId > b.tokenId) {
					return -1
				} else if (a.tokenId < b.tokenId) {
					return 1
				}
			})
		},
		mint() {
			judgeNetwork().then(res => {
				if (res == 0) {
					return false
				} else {
					var t = new Date().getTime()
					if ( t > this.ClaimablePeriodStart && t < this.ClaimablePeriodEnd) {
						if(!this.winners){
							return false
						}
						if(this.mintting){
							return false
						}	
						this.mintting = true
						if(this.isCosmos){
							var msgParamsData = {
								name:this.nftInfo.name,
								value:this.$sendWeb3.utils.asciiToHex(this.addressValue)
							}
							sign(this.stationAddress,this.addressValue,this.cid,this.contractAddr,this.userAddress,msgParamsData).then(res => {
								this.mintAfter()
							}).catch(err => {
								this.$message({
									message:'NFT Minting Failed',
									duration:1000,
									type:'warning'
								});
								this.mintting = false
							})
						} else {
							claim(this.stationAddress,this.cid,this.contractAddr,this.cid,localStorage.getItem('userAddress')).then(res => {
								this.mintAfter()
							}).catch(err => {
								this.$message({
									message:'NFT Minting Failed',
									duration:1000,
									type:'warning'
								});
								this.mintting = false
							})
						}
					}
				}
			})
		},
		mintAfter(){
			nftBalanceOf(this.contractAddr,this.userAddress).then(balance => {
				this.nftBalance1 = balance
				//console.log(balance)
				tokenOfOwnerByIndex(this.contractAddr,balance-1,this.userAddress).then(tokenId => {
					//console.log(tokenId)
					tokenURI(this.contractAddr,tokenId).then(url => {
						//console.log(url)
						this.$.getJSON(url,(res) => {
							//console.log(res)
							this.nftInfo.image = res.image
						})
						this.$message({
							message:'Successfully minted',
							duration:1000,
							type:'success'
						});
						this.mintting = false
						this.minted = true
						mintTime(this.contractAddr,tokenId).then(time => {
							var obj = {
								tokenId:tokenId,
								addr:this.userAddress,
								time:time
							}

							this.mintedList.pop()
							this.mintedList.unshift(obj)
							this.$forceUpdate()
						})
					})
				})
			})
		},
		Receive() {
			this.$message({
				message:'Receive successfully',
				duration:1000,
				type:'success'
			});
			this.nftBalance = this.nftBalance1
		},
		copy() {
			var oInput = document.createElement('input');
			oInput.value = this.contractAddr;
			document.body.appendChild(oInput);
			oInput.select();
			document.execCommand("Copy");
			oInput.className = 'oInput';
			oInput.style.display='none';
			this.$message({
				message:'Copy contract address successfully',
				duration:1000,
				type:'success'
			});
		},
		connect() {
			this.$refs.vHeader.openConnect()
		},
		goMore(){
			// window.open(`https://escan.live/address/${this.contractAddr}`,'_blank')
			window.open(`https://escan.live/token/${this.contractAddr}`,'_blank')
		}
	}
}
</script>

<template>
  <div class="soulID">
    <v-Header></v-Header>
    <div class="soulIDBox">
		<div class="soulIDBanner bannerBox">
			<div class="centerBox">
				<div class="bannerBox">
					<div class="info">
						<div class="infoImg">
							<img src="../images/soulID/headImg.png" alt="">
						</div>
						<div class="infoEdit">
							<p class="userName">{{userAddress == '' ? 'Username' : changeAddress(userAddress)}}</p>
							<p class="edit">
								<!-- <img src="../images/soulID/edit.png" alt=""> EDIT -->
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="soulIDContent">
			<div class="centerBox">
				<div class="box">
					<div>
						<div class="btns">
							<p :class="tabIndex == 1 ? '' : 'no'" @click="change(1)">Badge</p>
							<p :class="tabIndex == 2 ? '' : 'no'" @click="change(2)">Stamp</p>
						</div>

						<!-- color: #D8D1C6; -->
						<div v-if="Loading" style="text-align: center;margin: 100px;">Loading <i class="el-icon-loading" ></i></div>
						<div v-else-if="!Loading">
							<p v-if="tabIndex == 1 && badgeList.length == 0" style="text-align: center;margin: 100px;">No data yet.</p>
							<ul v-if="tabIndex == 1 && badgeList.length > 0" class="myBadge content">
								<li v-for="(item,index) in badgeList" :key="index" @click="goBadgeDetail(item.id,item.nft_address)">
									<div>
										<div>
											<img :src="item.image" alt="">
										</div>
										<div>
											<p>{{item.name}}</p>
										</div>
									</div>
								</li>
							</ul>
							<p v-if="tabIndex == 2 && stampList.length == 0" style="text-align: center;margin: 100px;">No data yet.</p>
							<ul v-if="tabIndex == 2 && stampList.length > 0" class="myStamp content">
								<li v-for="(item,index) in stampList" :key="index" @click="goStampDetail(item.id)"><span>{{item.name}}</span></li>
							</ul>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="chooseBox" v-if="chooseName">
			<div>
				<div class="info">
					<label for="name">Choose Your Username</label>
					<input id="name" type="text" autocomplete="off">
					<div>Save</div>
				</div>
				<div class="close" @click="closeChoose"><img src="../images/close.png" alt=""></div>
			</div>
		</div>
	</div>
    <v-Footer></v-Footer>
  </div>
</template>

<script>
import { nftBalanceOf, tokenOfOwnerByIndex, tokenURI } from '../request.js'
export default {
  	name: 'SoulID',
	computed:{
		changeAddress() {
			return (val) => {
				var str = val
				return str.substring(0,4)+'****'+str.substring(38)
			}
		}
	},
	data () {
		return {
			userAddress:'',
			tabIndex:1,
			badgeList:[],
			stampList:[],
			chooseName:false,
			Loading:true
		}
	},
	mounted () {
		this.userAddress = localStorage.getItem('userAddress') == 'undefined' ? '' : localStorage.getItem('userAddress') || ''
		var appData = window.appData
		if(this.userAddress == ''){
			this.Loading = false
			this.badgeList = []
			this.stampList = []
			return false
			
		}
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getbadgelist'
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				var badgeData = data.data
				for (let i = 0; i < badgeData.length; i++) {
					nftBalanceOf(badgeData[i].nft_address,this.userAddress).then(balance => {
						if(balance > 0) {
							tokenOfOwnerByIndex(badgeData[i].nft_address,balance-1,this.userAddress).then(tokenId => {
								tokenURI(badgeData[i].nft_address,tokenId).then(url => {
									this.$.getJSON(url,(res) => {
										badgeData[i].name = res.name
										badgeData[i].image = res.image
										this.badgeList.push(badgeData[i])
										this.Loading = false
									})
									
								})
							})
						}else{
							this.Loading = false
						}
					})
				}
			} 
		});
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getstampbyaddress',
				address:this.userAddress
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.stampList = data.data
			} 
		});
	},
	methods: {
		change(index){
			this.tabIndex = index
		},
		closeChoose(){
			this.chooseName = false
		},
		goEdit(){
			this.$router.push({path:`/soulidedit`})
		},
		goBadgeDetail(id,addr){
			this.$router.push({path:`/badgedetail?id=${id}&addr=${addr}`})
		},
		goStampDetail(id){
			this.$router.push({path:`/stampdetail?id=${id}`})
		}
	}
}
</script>

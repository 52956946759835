<template>
	<div class="badge">
		<v-Header></v-Header>
		<div class="badgeBox">
			<!-- <div class="badgeBanner bannerBox">
				<div class="centerBox">
					<div class="bannerBox">
						<h1>Badge</h1>
						<p>Badge is your OAT ( on-chain achievement token ), a non-transferable NFT issued by specific Initiators. All your on-chain achievements can be recorded in different badge NFTs, part of your reputation.</p>
					</div>
				</div>
			</div> -->
			<div class="badgeBanner bannerBox">
				<div class="centerBox">
					<div class="bannerBox">
						<div class="bannerInfo">
							<h1>Badge</h1>
							<p>Badge is your OAT ( on-chain achievement token ), a non-transferable NFT issued by specific Initiators. All your on-chain achievements can be recorded in different badge NFTs, part of your reputation.</p>
						</div>
						<div class="bannerImg">
							<img src="../images/badge/bannerright.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<div class="badgeList">
				<div class="centerBox">
					<div class="listBox">
						<ul>
							<li v-for="(item,index) in list" :key="index">
								<div class="listItem" @click="goBadgeDetail(item.id)">
									<div class="itemImg">
										<img :src="item.image" alt="">
									</div>
									<div class="ItemInfo">
										<p>{{item.name}}</p>
										<div>
											<div>
												<img :src="item.author_image" alt="">
											</div>
											<span>{{item.author_name}}</span>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<v-Footer></v-Footer>
	</div>
</template>

<script>
export default {
	name: 'Badge',
	components: {
		
	},
	data () {
		return {
			list:[]
		}
	},
	mounted () {
		var appData = window.appData
		// this.list = appData.station.badgeList
		this.$.ajax({
			type: "GET",
			url: this.$apiUrl,
			data: {
				method:'soul.stamp.getbadgelist'
			},
			xhrFields:{ withCredentials:true },
			dataType: "json",
			success: (data) => {
				this.list = data.data
			} 
		});

	},
	methods: {
		goBadgeDetail(id){
			this.$router.push({path:`/badgedetail?id=${id}`})
		}
	}
}
</script>
